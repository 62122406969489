var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "data-range" },
    [
      _c("date-range-picker", {
        ref: "picker",
        attrs: {
          opens: _vm.dateRangePickerConfig.opens,
          "locale-data": _vm.localeData,
          singleDatePicker: _vm.dateRangePickerConfig.singleDatePicker,
          showDropdowns: _vm.dateRangePickerConfig.showDropdowns,
          autoApply: _vm.dateRangePickerConfig.autoApply,
          showWeekNumbers: _vm.dateRangePickerConfig.showWeekNumbers,
          maxDate: _vm.dateRangePickerConfig.maxDate,
        },
        on: { update: _vm.updateValues },
        scopedSlots: _vm._u([
          {
            key: "input",
            fn: function (picker) {
              return [
                _c("div", { staticClass: "date-range-input-value" }, [
                  _vm._v(
                    _vm._s(_vm._f("date")(picker.startDate)) +
                      " - " +
                      _vm._s(_vm._f("date")(picker.endDate))
                  ),
                ]),
              ]
            },
          },
        ]),
        model: {
          value: _vm.dateRangeObject,
          callback: function ($$v) {
            _vm.dateRangeObject = $$v
          },
          expression: "dateRangeObject",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }