var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-popup",
        {
          staticClass: "invoice-popup",
          attrs: {
            title: "Invoice #" + (_vm.currentInvoice && _vm.currentInvoice.id),
            active: _vm.invoicePopupShow,
          },
          on: {
            "update:active": function ($event) {
              _vm.invoicePopupShow = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "relative",
              staticStyle: { "max-width": "750px", width: "100%" },
            },
            [
              _vm.invoicePopupShow
                ? _c("InvoicePreview", {
                    attrs: { currentInvoice: _vm.currentInvoice },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "invoices" },
        [
          _c(
            "div",
            {
              staticClass: "mb-2",
              staticStyle: {
                display: "flex",
                "flex-direction": "row",
                "justify-content": "flex-end",
              },
            },
            [
              _c(
                "div",
                { staticClass: "ml-2" },
                [
                  _c(
                    "vs-button",
                    {
                      attrs: {
                        disabled: _vm.filteredSelectedInvoices.length === 0,
                      },
                      on: { click: _vm.downloadMultipleInvoices },
                    },
                    [
                      _vm._v(
                        "\n          Download Invoices (" +
                          _vm._s(_vm.filteredSelectedInvoices.length) +
                          ")\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "filters-container ml-2" },
                [
                  _c("feather-icon", {
                    staticClass: "filters-container-icon-date_range",
                    attrs: { icon: "CalendarIcon" },
                  }),
                  _c("invoice-date-range-filter", {
                    attrs: { localeData: _vm.localeData },
                    on: { dateRangeChanged: _vm.handleDateRangeChange },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "ml-2" },
                [
                  _c("multiselect", {
                    staticStyle: { "z-index": "999" },
                    attrs: {
                      options: _vm.paymentMethods,
                      multiple: false,
                      "close-on-select": true,
                      "clear-on-select": false,
                      placeholder: "Choose payment method",
                      selectLabel: "",
                      selectedLabel: "",
                      deselectLabel: "",
                      label: "name",
                      "track-by": "value",
                      "open-direction": "bottom",
                    },
                    model: {
                      value: _vm.paymentMethod,
                      callback: function ($$v) {
                        _vm.paymentMethod = $$v
                      },
                      expression: "paymentMethod",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "ml-2" },
                [
                  _c("multiselect", {
                    staticStyle: { "z-index": "999" },
                    attrs: {
                      options: _vm.statuses,
                      multiple: false,
                      "close-on-select": true,
                      "clear-on-select": false,
                      placeholder: "Choose status",
                      selectLabel: "",
                      selectedLabel: "",
                      deselectLabel: "",
                      label: "id",
                      "track-by": "value",
                      "open-direction": "bottom",
                    },
                    model: {
                      value: _vm.selectedStatus,
                      callback: function ($$v) {
                        _vm.selectedStatus = $$v
                      },
                      expression: "selectedStatus",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "vs-row",
            { attrs: { "vs-type": "flex", "vs-justify": "flex-end" } },
            [
              _c("vs-col", {
                attrs: {
                  "vs-type": "flex",
                  "vs-justify": "center",
                  "vs-align": "end",
                  "vs-w": "3",
                },
              }),
              _c("vs-col", {
                attrs: {
                  "vs-type": "flex",
                  "vs-justify": "center",
                  "vs-align": "end",
                  "vs-w": "3",
                },
              }),
            ],
            1
          ),
          _c(
            "vs-card",
            [
              _c(
                "vs-table",
                {
                  ref: "table",
                  staticClass: "invoices-table",
                  attrs: {
                    multiple: "",
                    pagination: "",
                    "max-items": _vm.itemsPerPage,
                    data: _vm.normalizedPathadviceInvoices,
                    noDataText: _vm.$t("views.home.noDataAvailable"),
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var data = ref.data
                        return [
                          _c(
                            "tbody",
                            _vm._l(data, function (tr) {
                              return _c(
                                "vs-tr",
                                { key: tr.id, attrs: { data: tr } },
                                [
                                  _c("vs-td", [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "product-name font-medium truncate",
                                      },
                                      [_vm._v(_vm._s(tr.company))]
                                    ),
                                  ]),
                                  _c("vs-td", [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "product-name font-medium truncate",
                                      },
                                      [_vm._v(_vm._s(_vm.formatDate(tr.date)))]
                                    ),
                                  ]),
                                  _c("vs-td", [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "product-name font-medium truncate",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(tr.subscription || tr.title)
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("vs-td", [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "product-name font-medium truncate text-center",
                                      },
                                      [_vm._v(_vm._s(tr.amount.toFixed(2)))]
                                    ),
                                  ]),
                                  _c("vs-td", [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "product-name font-medium truncate",
                                      },
                                      [
                                        _c(
                                          "vs-chip",
                                          {
                                            attrs: {
                                              color: _vm.colorForPaymentMethod(
                                                tr.method
                                              ),
                                            },
                                          },
                                          [
                                            _c("vs-avatar", {
                                              attrs: { icon: "payment" },
                                            }),
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(tr.method) +
                                                "\n                  "
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass:
                                        "invoices-table__statuses-td",
                                    },
                                    _vm._l(
                                      _vm.statuses.filter(function (x) {
                                        return x.methods.includes(tr.method)
                                      }),
                                      function (status) {
                                        return _c(
                                          "div",
                                          {
                                            key: status.id,
                                            staticClass:
                                              "invoices-table__statuses",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "invoices-table__statuses__status",
                                                class: {
                                                  "invoices-table__statuses__status--open":
                                                    status.value === "open",
                                                  "invoices-table__statuses__status--paid":
                                                    status.value === "paid",
                                                  "invoices-table__statuses__status--unpaid":
                                                    status.value === "unpaid",
                                                  "invoices-table__statuses__status--expired":
                                                    status.value === "expired",
                                                  "invoices-table__statuses__status--active":
                                                    status.value === tr.status,
                                                },
                                                on: {
                                                  click: function () {
                                                    return _vm.onStatusChange(
                                                      status.value,
                                                      tr.id,
                                                      tr.method
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(status.value) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass:
                                        "whitespace-no-wrap text-center",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "flex justify-center",
                                          style: {
                                            direction: _vm.$vs.rtl
                                              ? "rtl"
                                              : "ltr",
                                          },
                                        },
                                        [
                                          _c(
                                            "vx-tooltip",
                                            {
                                              staticClass: "mr-4",
                                              attrs: {
                                                text: "Show Invoice",
                                                position: "top",
                                              },
                                            },
                                            [
                                              _c("vs-button", {
                                                attrs: {
                                                  type: "border",
                                                  size: "medium",
                                                  color: "#3B86F7",
                                                  "icon-pack": "feather",
                                                  icon: "icon-info",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.showInvoice(
                                                      tr.id
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          tr.previewUrl &&
                                          tr.previewUrl.length > 0
                                            ? _c(
                                                "vx-tooltip",
                                                {
                                                  staticClass: "mb-4 mr-4",
                                                  attrs: {
                                                    text: _vm.$t(
                                                      "vue.download"
                                                    ),
                                                    position: "top",
                                                  },
                                                },
                                                [
                                                  _c("vs-button", {
                                                    attrs: {
                                                      color: "primary",
                                                      type: "border",
                                                      size: "medium",
                                                      "icon-pack": "feather",
                                                      icon: "icon-download",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.downloadInvoice(
                                                          tr
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _c(
                                                "vx-tooltip",
                                                {
                                                  staticClass: "mb-4 mr-4",
                                                  attrs: {
                                                    text: _vm.$t(
                                                      "vue.generateInvoice"
                                                    ),
                                                    position: "top",
                                                  },
                                                },
                                                [
                                                  !tr.generatingInvoice
                                                    ? _c("vs-button", {
                                                        attrs: {
                                                          color: "warning",
                                                          type: "border",
                                                          size: "medium",
                                                          "icon-pack":
                                                            "feather",
                                                          icon: "icon-codepen",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.generateInvoice(
                                                              tr
                                                            )
                                                          },
                                                        },
                                                      })
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "fill-row-loading",
                                                        },
                                                        [
                                                          _c("div", {
                                                            staticClass:
                                                              "vs-con-loading__container loading-indicator",
                                                            attrs: {
                                                              id: "bt-" + tr.id,
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                ],
                                                1
                                              ),
                                          _c(
                                            "vx-tooltip",
                                            {
                                              staticClass: "mb-4 mr-4",
                                              attrs: {
                                                text: _vm.$t("vue.sendEmail"),
                                                position: "top",
                                              },
                                            },
                                            [
                                              _c("vs-button", {
                                                staticClass:
                                                  "vs-con-loading__container",
                                                attrs: {
                                                  color: "primary",
                                                  type: "border",
                                                  size: "medium",
                                                  "icon-pack": "feather",
                                                  icon: "icon-file-text",
                                                  disabled:
                                                    tr.previewUrl &&
                                                    tr.previewUrl.trim()
                                                      .length === 0,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.sendInvoiceEmail(
                                                      tr
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.selectedInvoices,
                    callback: function ($$v) {
                      _vm.selectedInvoices = $$v
                    },
                    expression: "selectedInvoices",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "thead" },
                    [
                      _c(
                        "vs-th",
                        {
                          staticClass: "pricing-invoices__table__heading-title",
                          attrs: { "sort-key": "company" },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("pricing.company")) + " ")]
                      ),
                      _c(
                        "vs-th",
                        {
                          staticClass: "pricing-invoices__table__heading-title",
                          attrs: { "sort-key": "date" },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("vue.date")) + " ")]
                      ),
                      _c(
                        "vs-th",
                        {
                          staticClass: "pricing-invoices__table__heading-title",
                          attrs: { "sort-key": "subscription" },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("pricing.subscription")) + " "
                          ),
                        ]
                      ),
                      _c(
                        "vs-th",
                        {
                          staticClass:
                            "pricing-invoices__table__heading-title invoices-table__heading-title_text-center",
                          attrs: { "sort-key": "amount" },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("pricing.amount")) +
                              "\n          "
                          ),
                        ]
                      ),
                      _c(
                        "vs-th",
                        {
                          staticClass:
                            "pricing-invoices__table__heading-title invoices-table__heading-title",
                          attrs: { "sort-key": "method" },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("pricing.paymentMethod")) +
                              "\n          "
                          ),
                        ]
                      ),
                      _c(
                        "vs-th",
                        {
                          staticClass:
                            "pricing-invoices__table__heading-title invoices-table__heading-title_text-center",
                          attrs: { "sort-key": "status" },
                        },
                        [_vm._v(" Status ")]
                      ),
                      _c(
                        "vs-th",
                        {
                          staticClass:
                            "text-center pricing-invoices__table__heading-title invoices-table__heading-title_text-center",
                          attrs: { "sort-key": "id" },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("vue.action")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }