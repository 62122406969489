var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "container-fluid invoice-container",
        staticStyle: {
          width: "100%",
          "padding-right": "15px",
          "padding-left": "15px",
          "margin-right": "auto",
          "margin-left": "auto",
          padding: "70px",
          "max-width": "750px",
          "background-color": "#fff",
          border: "1px solid #ccc",
          "-moz-border-radius": "6px",
          "-webkit-border-radius": "6px",
          "-o-border-radius": "6px",
          "border-radius": "6px",
          "box-sizing": "border-box",
        },
      },
      [
        _c("header", { staticStyle: { "box-sizing": "border-box" } }, [
          _c(
            "div",
            {
              staticClass: "row align-items-center",
              staticStyle: {
                display: "flex",
                "flex-wrap": "wrap",
                "margin-top": "calc(0rem * -1)",
                "margin-right": "calc(1.5rem * -0.5)",
                "margin-left": "calc(1.5rem * -0.5)",
                "align-items": "center !important",
                "box-sizing": "border-box",
              },
            },
            [
              _vm._m(0),
              _c(
                "div",
                {
                  staticClass: "col-sm-5 text-center text-sm-end",
                  staticStyle: {
                    flex: "0 0 auto",
                    "text-align": "right !important",
                    "-ms-flex-preferred-size": "0",
                    "flex-basis": "0",
                    "-ms-flex-positive": "1",
                    "flex-grow": "1",
                    "max-width": "41.666667%",
                    position: "relative",
                    width: "100%",
                    "min-height": "1px",
                    "padding-right": "15px",
                    "padding-left": "15px",
                    "box-sizing": "border-box",
                  },
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "mb-0",
                      staticStyle: {
                        "line-height": "1.9",
                        "margin-bottom": "0 !important",
                        "box-sizing": "border-box",
                        display: "flex",
                        "flex-direction": "column",
                      },
                    },
                    [
                      _c("strong", [_vm._v("Invoice Number: ")]),
                      _vm._v(_vm._s(_vm.currentInvoice.id) + "\n          "),
                    ]
                  ),
                  _vm.currentInvoice.method === "invoice"
                    ? _c(
                        "p",
                        {
                          staticClass: "mb-0",
                          staticStyle: {
                            "line-height": "1.9",
                            "margin-bottom": "0 !important",
                            "box-sizing": "border-box",
                            display: "flex",
                            "flex-direction": "column",
                          },
                        },
                        [
                          _c("strong", [_vm._v("VAT Number: ")]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.currentInvoice.vatNumber
                                  ? _vm.currentInvoice.vatNumber
                                  : "-"
                              ) +
                              "\n          "
                          ),
                        ]
                      )
                    : _c(
                        "p",
                        {
                          staticClass: "mb-0",
                          staticStyle: {
                            "line-height": "1.9",
                            "margin-bottom": "0 !important",
                            "box-sizing": "border-box",
                            display: "flex",
                            "flex-direction": "column",
                          },
                        },
                        [
                          _c("strong", [_vm._v("VAT Number: ")]),
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.currentInvoice.companyInfo &&
                                  _vm.currentInvoice.companyInfo.vatNumber
                                  ? _vm.currentInvoice.companyInfo.vatNumber
                                  : "-"
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                  _c(
                    "p",
                    {
                      staticClass: "mb-0",
                      staticStyle: {
                        "line-height": "1.9",
                        "margin-bottom": "0 !important",
                        "box-sizing": "border-box",
                        display: "flex",
                        "flex-direction": "column",
                      },
                    },
                    [
                      _c("strong", [_vm._v("Customer ID: ")]),
                      _vm._v(
                        " " +
                          _vm._s(_vm.currentInvoice.companyId) +
                          "\n          "
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c("hr", {
            staticStyle: {
              margin: "1rem 0",
              color: "inherit",
              "background-color": "currentColor",
              border: "0",
              opacity: "0.15",
              height: "1px",
              "box-sizing": "border-box",
            },
          }),
        ]),
        _c("main", { staticStyle: { "box-sizing": "border-box" } }, [
          _c(
            "div",
            {
              staticClass: "row",
              staticStyle: {
                display: "flex",
                "flex-wrap": "wrap",
                "margin-top": "calc(0rem * -1)",
                "margin-right": "calc(1.5rem * -0.5)",
                "margin-left": "calc(1.5rem * -0.5)",
                "align-items": "center !important",
                "box-sizing": "border-box",
              },
            },
            [
              _vm._m(1),
              _c(
                "div",
                {
                  staticClass: "col-sm-6 order-sm-0",
                  staticStyle: {
                    "max-width": "100%",
                    "padding-right": "calc(1.5rem * 0.5)",
                    "padding-left": "calc(1.5rem * 0.5)",
                    "margin-top": "0",
                    flex: "0 0 auto",
                    width: "50%",
                    order: "0 !important",
                    "box-sizing": "border-box",
                  },
                },
                [
                  _c(
                    "strong",
                    {
                      staticStyle: {
                        "font-weight": "bolder",
                        "box-sizing": "border-box",
                      },
                    },
                    [_vm._v("Billed To:")]
                  ),
                  _vm.currentInvoice.method === "invoice"
                    ? _c(
                        "address",
                        {
                          staticStyle: {
                            "margin-bottom": "1rem",
                            "font-style": "normal",
                            "line-height": "inherit",
                            "box-sizing": "border-box",
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.currentInvoice.company) +
                              " "
                          ),
                          _c("br"),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.currentInvoice.title.name) +
                              " " +
                              _vm._s(_vm.currentInvoice.academicTitle) +
                              " " +
                              _vm._s(_vm.currentInvoice.firstName) +
                              " " +
                              _vm._s(_vm.currentInvoice.lastName) +
                              " "
                          ),
                          _c("br"),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.currentInvoice.streetAddress)
                          ),
                          _c("br"),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.currentInvoice.zipOrPostalCode) +
                              " " +
                              _vm._s(_vm.currentInvoice.city)
                          ),
                          _c("br"),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.currentInvoice.country.countryName) +
                              "\n          "
                          ),
                        ]
                      )
                    : _c(
                        "address",
                        {
                          staticStyle: {
                            "margin-bottom": "1rem",
                            "font-style": "normal",
                            "line-height": "inherit",
                            "box-sizing": "border-box",
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.currentInvoice.companyInfo.name) +
                              " "
                          ),
                          _c("br"),
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.currentInvoice.companyInfo.createdByName
                              ) +
                              " "
                          ),
                          _c("br"),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.currentInvoice.companyInfo.street)
                          ),
                          _c("br"),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.currentInvoice.companyInfo.zipcode) +
                              " " +
                              _vm._s(_vm.currentInvoice.companyInfo.city)
                          ),
                          _c("br"),
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.currentInvoice.companyInfo.country &&
                                  _vm.currentInvoice.companyInfo.countryName
                                  ? _vm.currentInvoice.companyInfo.countryName
                                  : ""
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "row",
              staticStyle: {
                display: "flex",
                "flex-wrap": "wrap",
                "margin-top": "1.5rem",
                "margin-right": "calc(1.5rem * -0.5)",
                "margin-left": "calc(1.5rem * -0.5)",
                "box-sizing": "border-box",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "col-sm-6",
                  staticStyle: {
                    "max-width": "100%",
                    "padding-right": "calc(1.5rem * 0.5)",
                    "padding-left": "calc(1.5rem * 0.5)",
                    "margin-top": "0",
                    flex: "0 0 auto",
                    width: "50%",
                    "box-sizing": "border-box",
                  },
                },
                [
                  _c(
                    "strong",
                    {
                      staticStyle: {
                        "font-weight": "bolder",
                        "box-sizing": "border-box",
                      },
                    },
                    [_vm._v("Payment Method:")]
                  ),
                  _c("br"),
                  _c("span", { staticStyle: { "box-sizing": "border-box" } }, [
                    _vm._v(_vm._s(_vm.currentInvoice.method)),
                  ]),
                  _c("br"),
                  _c("br"),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "col-sm-6 text-sm-end",
                  staticStyle: {
                    "max-width": "100%",
                    "padding-right": "calc(1.5rem * 0.5)",
                    "padding-left": "calc(1.5rem * 0.5)",
                    "margin-top": "0",
                    flex: "0 0 auto",
                    width: "50%",
                    "text-align": "right !important",
                    "box-sizing": "border-box",
                  },
                },
                [
                  _c(
                    "strong",
                    {
                      staticStyle: {
                        "font-weight": "bolder",
                        "box-sizing": "border-box",
                      },
                    },
                    [_vm._v("Order Date:")]
                  ),
                  _c("br"),
                  _c("span", { staticStyle: { "box-sizing": "border-box" } }, [
                    _vm._v("\n            " + _vm._s(_vm.invoiceCreatedAt)),
                    _c("br"),
                    _c("br"),
                  ]),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "row",
              staticStyle: {
                display: "flex",
                "flex-wrap": "wrap",
                "margin-top": "1.5rem",
                "margin-right": "calc(1.5rem * -0.5)",
                "margin-left": "calc(1.5rem * -0.5)",
                "box-sizing": "border-box",
              },
            },
            [
              _c("div", {
                staticClass: "col-sm-6",
                staticStyle: {
                  "max-width": "100%",
                  "padding-right": "calc(1.5rem * 0.5)",
                  "padding-left": "calc(1.5rem * 0.5)",
                  "margin-top": "0",
                  flex: "0 0 auto",
                  width: "50%",
                  "box-sizing": "border-box",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "col-sm-6 text-sm-end",
                  staticStyle: {
                    "max-width": "100%",
                    "padding-right": "calc(1.5rem * 0.5)",
                    "padding-left": "calc(1.5rem * 0.5)",
                    "margin-top": "0",
                    flex: "0 0 auto",
                    width: "50%",
                    "text-align": "right !important",
                    "box-sizing": "border-box",
                  },
                },
                [
                  _c(
                    "strong",
                    {
                      staticStyle: {
                        "font-weight": "bolder",
                        "box-sizing": "border-box",
                      },
                    },
                    [_vm._v("Service period:")]
                  ),
                  _c("br"),
                  _vm.currentInvoice.source === "vega-payment"
                    ? _c(
                        "span",
                        { staticStyle: { "box-sizing": "border-box" } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.servicePeriodStart) +
                              " - " +
                              _vm._s(_vm.servicePeriodEnd)
                          ),
                          _c("br"),
                          _c("br"),
                        ]
                      )
                    : _c(
                        "span",
                        { staticStyle: { "box-sizing": "border-box" } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.invoiceCreatedAt) +
                              " - " +
                              _vm._s(_vm.invoiceDueDate)
                          ),
                          _c("br"),
                          _c("br"),
                        ]
                      ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "card",
              staticStyle: {
                position: "relative",
                display: "flex",
                "flex-direction": "column",
                "min-width": "0",
                "word-wrap": "break-word",
                "background-color": "#fff",
                "background-clip": "border-box",
                border: "1px solid rgba(0, 0, 0, 0.125)",
                "border-radius": "0.25rem",
              },
            },
            [
              _vm._m(2),
              _c(
                "div",
                {
                  staticClass: "card-body p-0",
                  staticStyle: { padding: "0 !important", flex: "1 1 auto" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "table-responsive",
                      staticStyle: {
                        "overflow-x": "auto",
                        "-webkit-overflow-scrolling": "touch",
                      },
                    },
                    [
                      _c(
                        "table",
                        {
                          staticClass: "table mb-0",
                          staticStyle: {
                            "--bs-table-bg": "transparent",
                            "--bs-table-accent-bg": "transparent",
                            "--bs-table-striped-color": "#212529",
                            "--bs-table-striped-bg": "rgba(0, 0, 0, 0.05)",
                            "--bs-table-active-color": "#212529",
                            "--bs-table-active-bg": "rgba(0, 0, 0, 0.1)",
                            "--bs-table-hover-color": "#212529",
                            "--bs-table-hover-bg": "rgba(0, 0, 0, 0.075)",
                            width: "100%",
                            "margin-bottom": "0 !important",
                            color: "#212529",
                            "vertical-align": "top",
                            "border-color": "#dee2e6",
                          },
                        },
                        [
                          _vm._m(3),
                          _c("tbody", [
                            _c("tr", [
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    "background-color":
                                      "transparent !important",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "text-3",
                                      staticStyle: {
                                        color: "#535b61",
                                        padding: "0.5rem",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.currentInvoice.subscription)
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "td",
                                {
                                  staticClass: "text-center",
                                  staticStyle: {
                                    "background-color":
                                      "transparent !important",
                                    color: "#535b61",
                                    padding: "0.5rem",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.currentInvoice.billingType) +
                                      "\n                  "
                                  ),
                                ]
                              ),
                              _c(
                                "td",
                                {
                                  staticClass: "text-end",
                                  staticStyle: {
                                    "background-color":
                                      "transparent !important",
                                    color: "#535b61",
                                    "text-align": "right",
                                    padding: "0.5rem",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.planAmount) +
                                      " €\n                  "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          _c(
                            "tfoot",
                            {
                              staticClass: "card-footer",
                              staticStyle: {
                                "border-color": "inherit",
                                "border-style": "solid",
                                "border-width": "0",
                                padding: "0.5rem 1rem",
                                "background-color": "rgba(0, 0, 0, 0.03)",
                                "border-top": "1px solid rgba(0, 0, 0, 0.125)",
                                "border-radius":
                                  "0 0 calc(0.25rem - 1px) calc(0.25rem - 1px)",
                              },
                            },
                            [
                              _c("tr", [
                                _vm._m(4),
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-end",
                                    staticStyle: {
                                      color: "#535b61",
                                      padding: "0.5rem 0.5rem",
                                      "background-color": "transparent",
                                      "border-bottom-width": "1px",
                                      "box-shadow":
                                        "inset 0 0 0 9999px transparent",
                                      "text-align": "right !important",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.planAmount) +
                                        " €\n                  "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-end",
                                    staticStyle: {
                                      color: "#535b61",
                                      padding: "0.5rem 0.5rem",
                                      "background-color": "transparent",
                                      "border-bottom-width": "1px",
                                      "box-shadow":
                                        "inset 0 0 0 9999px transparent",
                                      "text-align": "right !important",
                                    },
                                    attrs: { colspan: "2" },
                                  },
                                  [
                                    _c("strong", [
                                      _vm._v(
                                        "VAT " + _vm._s(_vm.vatPercent) + " %:"
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-end",
                                    staticStyle: {
                                      color: "#535b61",
                                      padding: "0.5rem 0.5rem",
                                      "background-color": "transparent",
                                      "border-bottom-width": "1px",
                                      "box-shadow":
                                        "inset 0 0 0 9999px transparent",
                                      "text-align": "right !important",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.taxAmount) +
                                        " €\n                  "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("tr", [
                                _vm._m(5),
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-end border-bottom-0",
                                    staticStyle: {
                                      color: "#535b61",
                                      padding: "0.5rem 0.5rem",
                                      "background-color": "transparent",
                                      "border-bottom-width": "1px",
                                      "box-shadow":
                                        "inset 0 0 0 9999px transparent",
                                      "text-align": "right !important",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.totalPrice) +
                                        " €\n                  "
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c("br"),
        ]),
        _vm._m(6),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "col-sm-7 text-center text-sm-start mb-3 mb-sm-0",
        staticStyle: {
          "-ms-flex-preferred-size": "0",
          "flex-basis": "0",
          "-ms-flex-positive": "1",
          "flex-grow": "1",
          "max-width": "58.333333%",
          position: "relative",
          width: "100%",
          "min-height": "1px",
          "padding-right": "15px",
          "padding-left": "15px",
          "margin-bottom": "1rem !important",
          "text-align": "left !important",
          flex: "0 0 auto",
          "margin-top": "0",
          "box-sizing": "border-box",
        },
      },
      [
        _c("img", {
          staticStyle: {
            "vertical-align": "inherit",
            "box-sizing": "border-box",
          },
          attrs: {
            id: "logo",
            src: "https://firebasestorage.googleapis.com/v0/b/pathadvice-app.appspot.com/o/LetsConnect_Logo.4d2c677c.svg?alt=media&token=44e1f2f4-1c13-4dea-9b03-c0d4c48cc3f4",
            title: "Let's Connect!",
            alt: "Let's Connect!",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "col-sm-6 text-sm-end order-sm-1",
        staticStyle: {
          "flex-shrink": "0",
          width: "50%",
          "max-width": "100%",
          "padding-right": "calc(1.5rem * 0.5)",
          "padding-left": "calc(1.5rem * 0.5)",
          "margin-top": "0",
          flex: "0 0 auto",
          order: "1 !important",
          "text-align": "right !important",
          "box-sizing": "border-box",
          "margin-bottom": "auto",
        },
      },
      [
        _c(
          "strong",
          {
            staticStyle: {
              "font-weight": "bolder",
              "box-sizing": "border-box",
            },
          },
          [_vm._v("Pay To:")]
        ),
        _c(
          "address",
          {
            staticStyle: {
              "margin-bottom": "1rem",
              "font-style": "normal",
              "line-height": "inherit",
              "box-sizing": "border-box",
            },
          },
          [
            _vm._v("\n            PATHADVICE International GmbH"),
            _c("br"),
            _vm._v("\n            Serlesweg 3"),
            _c("br"),
            _vm._v("\n            6161 Natters"),
            _c("br"),
            _vm._v("\n            AUSTRIA\n          "),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "card-header",
        staticStyle: {
          padding: "0.5rem 1rem",
          "margin-bottom": "0",
          "background-color": "rgba(0, 0, 0, 0.03)",
          "border-bottom": "1px solid rgba(0, 0, 0, 0.125)",
          "border-radius": "calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0",
        },
      },
      [
        _c(
          "span",
          {
            staticClass: "fw-600 text-4",
            staticStyle: {
              "font-size": "1.125rem !important",
              "font-weight": "600 !important",
            },
          },
          [_vm._v("Order Summary")]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "thead",
      {
        staticStyle: {
          "border-color": "inherit",
          "border-style": "solid",
          "border-width": "0",
          "vertical-align": "bottom",
        },
      },
      [
        _c(
          "tr",
          {
            staticStyle: {
              "border-color": "inherit",
              "border-style": "solid",
              "border-width": "0",
            },
          },
          [
            _c(
              "td",
              {
                staticClass: "col-8",
                staticStyle: {
                  "border-color": "inherit",
                  "border-style": "solid",
                  "border-width": "0",
                  flex: "0 0 auto",
                  width: "66.66666667%",
                  padding: "0.5rem 0.5rem",
                  "background-color": "transparent",
                  "border-bottom-width": "1px",
                  "box-shadow": "inset 0 0 0 9999px transparent",
                },
              },
              [
                _c(
                  "strong",
                  {
                    staticStyle: { "font-weight": "bolder", color: "#535b61" },
                  },
                  [_vm._v("Pricing plan")]
                ),
              ]
            ),
            _c(
              "td",
              {
                staticClass: "col-2 text-center",
                staticStyle: {
                  "border-color": "inherit",
                  "border-style": "solid",
                  "border-width": "0",
                  flex: "0 0 auto",
                  width: "16.66666667%",
                  "text-align": "center !important",
                  padding: "0.5rem 0.5rem",
                  "background-color": "transparent",
                  "border-bottom-width": "1px",
                  "box-shadow": "inset 0 0 0 9999px transparent",
                },
              },
              [
                _c(
                  "strong",
                  {
                    staticStyle: { "font-weight": "bolder", color: "#535b61" },
                  },
                  [_vm._v("Billing type")]
                ),
              ]
            ),
            _c(
              "td",
              {
                staticClass: "col-2 text-end",
                staticStyle: {
                  "border-color": "inherit",
                  "border-style": "solid",
                  "border-width": "0",
                  flex: "0 0 auto",
                  width: "16.66666667%",
                  "text-align": "right !important",
                  padding: "0.5rem 0.5rem",
                  "background-color": "transparent",
                  "border-bottom-width": "1px",
                  "box-shadow": "inset 0 0 0 9999px transparent",
                },
              },
              [
                _c(
                  "strong",
                  {
                    staticStyle: { "font-weight": "bolder", color: "#535b61" },
                  },
                  [_vm._v("Amount in €")]
                ),
              ]
            ),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "td",
      {
        staticClass: "text-end",
        staticStyle: {
          color: "#535b61",
          padding: "0.5rem 0.5rem",
          "background-color": "transparent",
          "border-bottom-width": "1px",
          "box-shadow": "inset 0 0 0 9999px transparent",
          "text-align": "right !important",
        },
        attrs: { colspan: "2" },
      },
      [_c("strong", [_vm._v("Sub Total:")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "td",
      {
        staticClass: "text-end border-bottom-0",
        staticStyle: {
          color: "#535b61",
          padding: "0.5rem 0.5rem",
          "background-color": "transparent",
          "border-bottom-width": "1px",
          "box-shadow": "inset 0 0 0 9999px transparent",
          "text-align": "right !important",
        },
        attrs: { colspan: "2" },
      },
      [_c("strong", [_vm._v("Total:")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", { staticStyle: { "text-align": "left" } }, [
      _c(
        "p",
        {
          staticClass: "text-1",
          staticStyle: {
            "font-size": "0.75rem !important",
            display: "flex",
            "flex-direction": "column",
          },
        },
        [
          _c("strong", [_vm._v("Payment Details: ")]),
          _c("span", [
            _vm._v(
              "1. Please transfer your payment to the following bank account: IBAN: AT62 2050 3033 0277 8315 BIC: SPIHAT22XXX "
            ),
          ]),
        ]
      ),
      _c(
        "p",
        {
          staticClass: "text-1",
          staticStyle: { "font-size": "0.75rem !important" },
        },
        [
          _c("strong", [_vm._v("NOTE :")]),
          _vm._v(
            " Services subject to the reverse charge procedure - VAT must be paid by the recipient in accordance with Article 196 of EU\n        Directive 2006/112/EC the recipient is liable.\n      "
          ),
        ]
      ),
      _c(
        "p",
        {
          staticClass: "text-1",
          staticStyle: { "font-size": "0.75rem !important" },
        },
        [
          _c("strong", [_vm._v("NOTE :")]),
          _vm._v(
            " This is computer generated receipt and does not require physical signature.\n      "
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }